import { client } from 'services/storefront'
import { AppThunk } from 'store/store'
import { CartInput, CartLineInput } from 'etc/storefront-types'
import {
	ADD_TO_CART,
	CREATE_CART,
	REMOVE_FROM_CART,
	UPDATE_CART,
	UPDATE_DISCOUNT_CODES
} from 'etc/queries'
import {
	CartActions,
	CartItem,
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_EDIT_QUANTITY,
	CART_CREATE_CHECKOUT_REQUEST,
	CART_CREATE_CHECKOUT_SUCCESS,
	CART_UPDATE_CHECKOUT,
	CART_EDIT_NOTE,
	CART_CLEAR,
	CART_APPLY_DISCOUNT,
	CART_REMOVE_DISCOUNT,
	PartialCart
} from './types'

export const addItem = (item: CartItem): AppThunk => {
	return async (dispatch, getState) => {
		if (typeof window !== undefined && window.gtag) {
			window.gtag('event', 'add_to_cart', {
				items: [
					{
						id: item.handle,
						name: item.title,
						brand: item.vendor,
						quantity: item.quantity,
						price: item.variant.priceV2
					}
				]
			})
		}

		const {
			cart: { checkout }
		} = getState()

		const line: CartLineInput = {
			merchandiseId: item.id,
			quantity: item.quantity
		}

		const { data } = await client.mutate<{
			cartLinesAdd: { cart: PartialCart }
		}>({
			mutation: ADD_TO_CART,
			variables: {
				cartId: checkout?.id!,
				lines: [line]
			}
		})

		dispatch({ type: CART_ADD_ITEM, item, timestamp: Date.now() })

		if (data?.cartLinesAdd.cart) {
			dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data.cartLinesAdd.cart })
		}
	}
}

export const removeItem = (id: string): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { items, checkout }
		} = getState()

		if (typeof window !== undefined && window.gtag) {
			window.gtag('event', 'remove_from_cart', {
				items: [
					{
						id: items[id].handle,
						name: items[id].title,
						brand: items[id].vendor,
						quantity: items[id].quantity,
						price: items[id].variant.priceV2
					}
				]
			})
		}

		const lineId = checkout?.lines.nodes.find(node => node.merchandise.id === id)?.id

		const { data } = await client.mutate<{
			cartLinesRemove: { cart: PartialCart }
		}>({
			mutation: REMOVE_FROM_CART,
			variables: {
				cartId: checkout?.id!,
				lineIds: [lineId]
			}
		})

		dispatch({ type: CART_REMOVE_ITEM, id })

		if (data?.cartLinesRemove.cart) {
			dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data.cartLinesRemove.cart })
		}
	}
}

export const editQuantity = (id: string, quantity: number): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { checkout }
		} = getState()

		const lineId = checkout?.lines.nodes.find(node => node.merchandise.id === id)?.id

		const { data } = await client.mutate<{
			cartLinesUpdate: { cart: PartialCart }
		}>({
			mutation: UPDATE_CART,
			variables: {
				cartId: checkout?.id!,
				lines: [{ id: lineId, quantity }]
			}
		})

		dispatch({ type: CART_EDIT_QUANTITY, id, quantity })

		if (data?.cartLinesUpdate.cart) {
			dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data.cartLinesUpdate.cart })
		}
	}
}

const getLineItems = (items: { [id: string]: CartItem }): CartLineInput[] => {
	return Object.values(items).map(item => {
		return {
			merchandiseId: item.id,
			quantity: item.quantity,
			...(item.note && { attributes: [{ key: 'note', value: item.note }] })
		}
	})
}

export const createCheckout = (): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({ type: CART_CREATE_CHECKOUT_REQUEST })

		const {
			cart: { items }
		} = getState()

		const cartInput: CartInput = {
			lines: getLineItems(items)
		}

		const { data } = await client.mutate<{
			cartCreate: { cart: PartialCart }
		}>({
			mutation: CREATE_CART,
			variables: { cartInput }
		})

		if (data?.cartCreate.cart) {
			dispatch({ type: CART_CREATE_CHECKOUT_SUCCESS, checkout: data.cartCreate.cart })
		} else {
			throw Error('unable to create checkout')
		}
	}
}

export const updateCheckout = (): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { items, checkout }
		} = getState()

		if (!checkout?.id) return

		const lineItems = getLineItems(items)

		try {
			const { data } = await client.mutate<{
				cartLinesUpdate: { cart: PartialCart }
			}>({
				mutation: UPDATE_CART,
				variables: {
					cartId: checkout.id,
					lines: lineItems
				}
			})

			if (data?.cartLinesUpdate.cart) {
				dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data.cartLinesUpdate.cart })
			}
		} catch (err: any) {
			if (err.message === 'Checkout is already completed.') {
				dispatch(clearCart())
			}
		}
	}
}

export const applyDiscount = (code: string): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { checkout }
		} = getState()

		if (!checkout?.id) return

		const { data } = await client.mutate<{
			cartDiscountCodesUpdate: { cart: PartialCart }
		}>({
			mutation: UPDATE_DISCOUNT_CODES,
			variables: {
				cartId: checkout.id,
				discountCodes: [code]
			}
		})

		if (data?.cartDiscountCodesUpdate.cart) {
			dispatch({ type: CART_APPLY_DISCOUNT, code })
			dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data.cartDiscountCodesUpdate.cart })
		}
	}
}

export const removeDiscount = (): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { checkout }
		} = getState()

		if (!checkout?.id) return

		const { data } = await client.mutate<{
			cartDiscountCodesUpdate: { cart: PartialCart }
		}>({
			mutation: UPDATE_DISCOUNT_CODES,
			variables: {
				cartId: checkout.id,
				discountCodes: []
			}
		})

		if (data?.cartDiscountCodesUpdate.cart) {
			dispatch({ type: CART_REMOVE_DISCOUNT })
			dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data?.cartDiscountCodesUpdate.cart })
		}
	}
}

export const editNote = (id: string, note?: string): CartActions => {
	return {
		type: CART_EDIT_NOTE,
		id,
		note
	}
}

export const clearCart = () => {
	return {
		type: CART_CLEAR
	}
}
